exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-xcad-js": () => import("./../../../src/pages/buy-xcad.js" /* webpackChunkName: "component---src-pages-buy-xcad-js" */),
  "component---src-pages-creators-js": () => import("./../../../src/pages/creators.js" /* webpackChunkName: "component---src-pages-creators-js" */),
  "component---src-pages-fans-js": () => import("./../../../src/pages/fans.js" /* webpackChunkName: "component---src-pages-fans-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nft-moments-js": () => import("./../../../src/pages/nft-moments.js" /* webpackChunkName: "component---src-pages-nft-moments-js" */),
  "component---src-pages-plugin-js": () => import("./../../../src/pages/plugin.js" /* webpackChunkName: "component---src-pages-plugin-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */)
}

